import React from 'react';
import { utilities } from 'reqhub';
import moment from 'moment';

import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      publicKey: JSON.parse(window.localStorage.getItem('publicKey')),
      privateKey: JSON.parse(window.localStorage.getItem('privateKey')),
      subject: JSON.parse(window.localStorage.getItem('subject')),
      toAddresses: JSON.parse(window.localStorage.getItem('toAddresses')),
      contentMode: JSON.parse(window.localStorage.getItem('contentMode')) || 'raw',
      htmlContent: JSON.parse(window.localStorage.getItem('htmlContent')),
      fetchUrl: JSON.parse(window.localStorage.getItem('fetchUrl')),
      status: JSON.parse(window.localStorage.getItem('status'))
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    window.localStorage.setItem(e.target.name, JSON.stringify(e.target.value));
  };

  generateHeaders = (type, publicKey, privateKey, requestUrl) => {
    const path = requestUrl;
    const timestamp = utilities.generateTimestamp();
    const nonce = utilities.generateNonce();
    const token = utilities.generateToken(publicKey, privateKey, timestamp, nonce, path);

    const headers = {
      [`${type}Key`]: publicKey,
      [`${type}Url`]: path,
      [`${type}Timestamp`]: timestamp,
      [`${type}Nonce`]: nonce,
      [`${type}Token`]: token
    };
    return { headers };
  }

  submit = (e) => {
    e.preventDefault();

    if (this.state.contentMode === 'raw') {
      this.sendEmail(this.state.htmlContent);
    } else if (this.state.contentMode === 'url') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'text/html'
        },
        mode: 'cors'
      };
      fetch(this.state.fetchUrl, requestOptions)
        .then((response) => response.text())
        .then((data) => {
          this.sendEmail(data);
        });
    }
  };

  sendEmail = (content) => {
    const payload = {
      subject: this.state.subject,
      to: this.state.toAddresses.split(',').map((s) => s.trim()),
      plainTextContent: '',
      htmlContent: content,
      copyAddresses: [],
      attachments: []
    };

    const url = 'https://public-test-api.spacegiraffe.io/email/send';
    const reqhubOptions = this.generateHeaders('Client', this.state.publicKey, this.state.privateKey, '/email/send')
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...reqhubOptions.headers
      },
      body: JSON.stringify(payload),
      mode: 'cors'
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const newStatus = `${data.message} at ${moment().format('h:mm:ss')} (${data.succeeded ? 'success' : 'error'})`;
        this.setState({
          status: newStatus
        });
        window.localStorage.setItem('status', JSON.stringify(newStatus));
      });
  };

  render() {
    return (
      <form className="wrapper" onSubmit={this.submit}>
        <h1>&#x1f4e7; Email tester</h1>
        <div className="form-group">
          <label htmlFor="publicKey">
            Public key
          </label>
          <input type="text" id="publicKey" name="publicKey"
            onChange={this.handleInputChange} value={this.state.publicKey || ''} />

          <label htmlFor="privateKey">
            Private key
          </label>
          <input type="text" id="privateKey" name="privateKey"
            onChange={this.handleInputChange} value={this.state.privateKey || ''} />

          <p>
            Get your keys from <a href="https://reqhub.io/SpaceGiraffe/email">https://reqhub.io/SpaceGiraffe/email</a>.
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="subject">
            Subject
          </label>
          <input type="text" id="subject" name="subject"
            onChange={this.handleInputChange} value={this.state.subject || ''} />

          <label htmlFor="toAddresses">
            To addresses (comma-separated)
          </label>
          <input type="text" id="toAddresses" name="toAddresses"
            onChange={this.handleInputChange} value={this.state.toAddresses || ''} />

          <div className="radio-group">
            <input type="radio" id="contentModeRaw" name="contentMode" value="raw"
              onChange={this.handleInputChange} checked={this.state.contentMode === 'raw'} />
            <label htmlFor="contentModeRaw">
              Raw
            </label>

            <input type="radio" id="contentModeUrl" name="contentMode" value="url"
              onChange={this.handleInputChange} checked={this.state.contentMode === 'url'} />
            <label htmlFor="contentModeUrl">
              URL
            </label>
          </div>

          {this.state.contentMode === 'raw' && (
            <React.Fragment>
              <label htmlFor="htmlContent">
                HTML content
              </label>
              <textarea type="text" id="htmlContent" name="htmlContent" rows="10"
                onChange={this.handleInputChange} value={this.state.htmlContent || ''} />
            </React.Fragment>
          )}

          {this.state.contentMode === 'url' && (
            <React.Fragment>
              <label htmlFor="fetchUrl">
                Fetch URL
              </label>
              <input type="text" id="fetchUrl" name="fetchUrl"
                onChange={this.handleInputChange} value={this.state.fetchUrl || ''} />

              <span>
                (You may need to ensure CORS is enabled if using your own server)
              </span>
            </React.Fragment>
          )}
        </div>

        <button type="submit">
          Send email
        </button>

        <p>
          {this.state.status}
        </p>
      </form>
    );
  }
}

export default App;
